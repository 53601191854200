import React from "react";
import Login from "../Login/index.js";

function Home() {
  return (
    
      <div className="app-container">
        <Login />
      </div>
  );
}

export default Home;
